import React, { FC, useState, useEffect, ButtonHTMLAttributes } from 'react';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import bcrypt from 'bcryptjs';
import { IUserLogin } from '../types/user.type';
import { login, loginWithGoogle, loginWithMicrosoft } from "../services/auth.service";
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import MicrosoftLogin from "react-microsoft-login";
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from "react-redux";
import { loginHandler } from "../store/slicers/authSlicer";
import * as actions from "../store/actions/index";

interface LoginPageProps {
    title: string;
}

// eslint-disable-next-line no-empty-pattern
const LoginPage: FC<LoginPageProps> = ({ }) => {
    const { t, } = useTranslation("translate");
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const [rememberPassword, setRememberPassword] = useState(false);

    const initialValues: IUserLogin = {
        email: "",
        password: "",
    };
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('This field is required!')
            .email('This is not a valid email.'),
        password: Yup.string()
            .required('This field is required!'),
    });

    const handleLogin = async (formValue: { email: string; password: string }) => {
        const { email, password } = formValue;
        let hashedPassword = '';
        if (rememberPassword) {
            // Hash the password before storing it
            hashedPassword = await bcrypt.hash(password, 10);
        }
        const payload = {
            email: email,
            password: password,
        };
        dispatch(loginHandler(payload)).then((data: any) => {
            if (data.type === `${actions.LOGIN}/${actions.REJECTED}`) {
                const resMessage =
                    (data.error && data.error.message) ||
                    data.error.message ||
                    data.error.message.toString();
                toast.error(data?.payload?.response?.data?.errorMessage, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2500,
                });
                return;
            }
            toast.success("Login Successfully!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 10,
            });
            navigate("/dashboard");
        });
        if (rememberPassword) {
            localStorage.setItem('rememberPassword', 'true');
            localStorage.setItem('email', email);
            localStorage.setItem('hashedPassword', hashedPassword);
        } else {
            localStorage.removeItem('rememberPassword');
            localStorage.removeItem('email');
            localStorage.removeItem('hashedPassword');
        }
    };

    useEffect(() => {
        const storedRememberPassword = localStorage.getItem('rememberPassword');
        if (storedRememberPassword === 'true') {
            setRememberPassword(true);
            const storedEmail = localStorage.getItem('email');
            initialValues.email = storedEmail || "";
        }
    }, []);


    const authGoogleHandler = (data: any) => {
        loginWithGoogle(data).then(
            (response) => {
                toast.success('Login Successfully!', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2500,
                });
                navigate("/dashboard");
                setTimeout(() => {
                    window.location.reload();
                }, 100);
            },
            (error: { response: { data: { message: any; }; }; message: any; toString: () => any; }) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                toast.error('Login Failed!')
            }
        );
    };

    const authMicrosoftHandler = (err: any, data: any) => {
        if (err) {
            toast.error(t('Login Failed or Popup blocked!'))
        } else {
            loginWithMicrosoft(data).then(
                (response: any) => {
                    toast.success('Login Successfully!', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 10,
                    });
                    navigate("/dashboard");
                    setTimeout(() => {
                        window.location.reload();
                    }, 100);
                },
                (error: { response: { data: { message: any; }; }; message: any; toString: () => any; }) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    toast.error('Login Failed!')
                })
        }
    };

    return (
        <>
            <div id="layoutAuthentication_content" className='d-flex flex-column justify-content-center mb-5 flex-fill'>
                <main >
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-5">
                                <div className="card shadow border-0 mb-5">

                                    <div className="card-body p-4">
                                        <h4 className="text-center fw-semibold mb-4">{t("loginPage.lblLogin")}</h4>
                                        <div className='d-flex justify-content-center mb-3'>
                                            <GoogleOAuthProvider clientId="641696911018-iuu89njpss18sfr45g8a3qov376i7u2j.apps.googleusercontent.com">
                                                {/* <button onClick={() => Glogin()}>
                                                <img alt="Google" height={30} width={30} src="https://react-oauth.vercel.app/images/google-logo.png"></img>
                                            </button> */}
                                                <GoogleLogin
                                                    onSuccess={(credentialResponse: any) => {
                                                        const googleUser = jwtDecode(credentialResponse.credential);
                                                        authGoogleHandler(googleUser);
                                                    }}
                                                    onError={() => {
                                                        console.log('Login Failed');
                                                    }}
                                                    useOneTap
                                                />
                                            </GoogleOAuthProvider>
                                        </div>
                                        <div className='d-flex justify-content-center mb-3'>
                                            <MicrosoftLogin clientId={process.env.REACT_APP_MICROSOFT_APP_ID || ''} authCallback={authMicrosoftHandler}>
                                                { /* Optional: you can add any content to be rendered between the tags here */}
                                            </MicrosoftLogin>
                                        </div>
                                        <div className='mb-4 text-center'>or use your account</div>
                                        <Formik
                                            initialValues={initialValues}
                                            validationSchema={validationSchema}
                                            onSubmit={handleLogin}>
                                            <Form>
                                                <div className="form-floating mb-4">
                                                    <Field className="form-control" id="inputEmail" name="email" type="email" placeholder="name@example.com" />
                                                    <label htmlFor="inputEmail">{t("loginPage.lblEmailAddress")}</label>
                                                    <ErrorMessage name="email" component="div" className="text-danger txt-error position-absolute" />
                                                </div>
                                                <div className="form-floating mb-4">
                                                    <Field className="form-control" id="inputPassword" name="password" type="password" placeholder="Password" />
                                                    <label htmlFor="inputPassword">{t("loginPage.lblPassword")}</label>
                                                    <ErrorMessage name="password" component="div" className="text-danger txt-error position-absolute" />
                                                </div>
                                                <div className="form-check mb-3 pt-1">
                                                    <input className="form-check-input" id="inputRememberPassword" type="checkbox" value="" checked={rememberPassword} onChange={(e) => setRememberPassword(e.target.checked)} />
                                                    <label className="form-check-label" htmlFor="inputRememberPassword">{t("loginPage.lblRememberPassword")}</label>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between mb-3">
                                                    <Link className="small text-decoration-none" to="/forgot-password">{t("loginPage.lblForgotPassword")}</Link>
                                                    <button className="btn btn-primary" type="submit" >{t("loginPage.btnClickLogin")}</button>
                                                </div>
                                                <div className="text-center">
                                                    <div className="small">Need an account ? <Link to="/register" className='text-decoration-none'>{t("loginPage.btnClickRegister")}</Link></div>
                                                </div>
                                            </Form>
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <div id="layoutAuthentication_footer">
                <footer className="py-3 text-center">
                    <div className="text-muted">Copyright &copy; {new Date().getFullYear()} </div>
                    {/* <div>
                                <a href="#">Privacy Policy</a>
                                &middot;
                                <a href="#">Terms &amp; Conditions</a>
                            </div> */}
                </footer>
            </div>
        </>
    )

}

export default LoginPage;